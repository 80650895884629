import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  appointments: {},
  appointment: {
    key: null,
    id: null,
    uuid: new Date().getTime
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        appointments: payload.data,
        pagination: payload.pagination,
        errorMessage: false,
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        appointment: payload.data == null ? initState.appointment : payload.data,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.SET_APPOINTMENT:
      return {
        ...state,
        appointment: payload.appointment,
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data,
      };
    default:
      return state;
  }
}
