import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Domains from '@iso/redux/domains/reducers';
import PriceCadre from '@iso/redux/priceCadre/reducers';
import Mentors from '@iso/redux/mentors/reducers';
import Mentees from '@iso/redux/mentees/reducers';
import Requests from '@iso/redux/requests/reducers';
import Payments from '@iso/redux/payments/reducers';
import appointments from '@iso/redux/appointments/reducers';
import appointmentReviews from '@iso/redux/appointmentReviews/reducers';
import Payouts from '@iso/redux/payouts/reducers';
import notifications from '@iso/redux/notification/reducers';


export default combineReducers({
  App,
  Auth,
  ThemeSwitcher,
  LanguageSwitcher,
  Domains,
  PriceCadre,
  Mentors,
  Mentees,
  Requests,
  Payments,
  appointments,
  appointmentReviews,
  Payouts,
  notifications
});
