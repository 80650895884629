const DOCUMENT = 'Mentor_';

const actions = {
  LOAD_FROM_API: DOCUMENT + 'LOAD_FROM_API',
  LOAD_FROM_API_SUCCESS: DOCUMENT + 'LOAD_FROM_API_SUCCESS',
  LOAD_FROM_API_ERROR: DOCUMENT + 'LOAD_FROM_API_ERROR',

  SET_MENTOR: 'SET_MENTOR',

  SAVE_INTO_API: DOCUMENT + 'SAVE_INTO_API',
  SAVE_INTO_API_ERROR: DOCUMENT + 'SAVE_INTO_API_ERROR',

  RESET_API_DOCUMENTS_ERROR: DOCUMENT + 'RESET_API_DOCUMENTS_ERROR',
  TOGGLE_API_HANDLE_MODAL: DOCUMENT + 'TOGGLE_API_HANDLE_MODAL',

  loadFromApi: (pagination, data) => {
    return { type: actions.LOAD_FROM_API, pagination, data };
  },

  loadFromApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: { pagination, data },
  }),

  loadFromApiError: error => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_API_HANDLE_MODAL,
    payload: { data },
  }),

  selectMentor: (mentor) => ({
    type: actions.SET_MENTOR,
    payload: { mentor },
  }),

  saveIntoApi: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_API,
    payload: { data, actionName },
  }),

  saveIntoAPIError: error => ({
    type: actions.SAVE_INTO_API_ERROR,
    payload: { error },
  }),
};
export default actions;
