export const loginUrl = 'admin/auth/login';
export const forgotPasswordUrl = 'admin/auth/forget-password';
export const resetPasswordUrl = 'admin/auth/reset-password';
export const changePasswordUrl = 'admin/auth/change-password';
export const getStates = 'common/get-states';
// Domain
export const addDomian = 'admin/domain/add';
export const updateDomian = 'admin/domain/update';
export const deleteDomian = 'admin/domain/delete';
export const getDomianList = 'admin/domain/list';

// PriceCadre
export const addCadre = 'admin/cadre/add';
export const updateCadre = 'admin/cadre/update';
export const deleteCadre = 'admin/cadre/delete';
export const getCadreList = 'admin/cadre/list';

// Mentor
export const addMentor = 'admin/mentor/add';
export const getMentorGeneralInfo = 'admin/mentor/general-info';
export const profileVerification = 'admin/mentor/profile-verification';
export const mentorEducation = 'admin/mentor/education';
export const mentorExperience = 'admin/mentor/experience';
export const mentorAddBankDetails = 'admin/mentor/bank-details';
export const mentorResumeUpload = 'admin/mentor/resume';
export const mentorOtherInfo = 'admin/mentor/other-info';
export const deleteMentor = 'admin/mentor/delete';
export const getMentorInfo = 'admin/mentor/info';
export const getMentorList = 'admin/mentor/list';

// Mentee
export const addMentee = 'admin/mentee/add';
export const getMenteeGeneralInfo = 'admin/mentee/general-info';
export const menteeEducation = 'admin/mentee/education';
export const menteeExperience = 'admin/mentee/experience';
export const menteeResumeUpload = 'admin/mentee/resume';
export const menteeOtherInfo = 'admin/mentee/other-info';
export const deleteMentee = 'admin/mentee/delete';
export const getMenteeList = 'admin/mentee/list';

// Common
export const getRequestList = 'admin/common/request-list';
export const getPaymentList = 'admin/common/payment-list';
export const getAppointmentsList = 'admin/common/appointment-list';
export const refundPayment = 'admin/common/refund';

// Review
export const getAppointmentsReviews = 'admin/common/appointment-reviews';
export const deleteReview = 'admin/common/delete-review';
export const updateReview = 'admin/common/update-review';

// Payouts
export const getPayoutList = 'admin/payout/list';
export const getPayoutDetails = 'admin/payout/get-payout-details'
export const proceedPayout = 'admin/payout/release-payout'

export const domainList = 'admin/mentor/domains';
export const pricingCadreList = 'admin/mentor/pricing-cadres';

// Notifications
export const notificationUrl = 'notification';
export const notificationMarkAsReadUrl = 'notification/mark-as-read';
export const notificationCountUrl = 'notification/count';

//Activity Logs
export const activityLogs = 'admin/activity-log';
