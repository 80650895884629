import { toast } from 'react-toastify'
export const showErrorToaster = (message, toastId = '') => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
    toastId,
  })
}


export const showsuccessToaster = (message, toastId = '') => {
  return toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
    toastId,
  })
}
