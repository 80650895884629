import useRequestToServer from '../services/httpService';
import { domainList, pricingCadreList, getStates } from '../services/reqUrl.service';
import React, { useContext, useMemo, useEffect } from 'react'


const CommonApiContext = React.createContext({
    domains: [],
    pricingCadres: [],
    states: [],
});

export const CommonApiProvider = ({ children }) => {

    const { requestToServer } = useRequestToServer()
    const [domains, setDomains] = React.useState([])
    const [pricingCadres, setPricingCadre] = React.useState([])
    const [states, setStates] = React.useState([])

    const fetchDomains = async () => {
        let { data, status } = (await requestToServer(
            domainList,
            '',
            'get',
            true
        ))
        if (status === 200) {
            setDomains(data?.data)
        }
    }

    const fetchPricingCadres = async () => {
        let { data, status } = (await requestToServer(
            pricingCadreList,
            '',
            'get',
            true
        ))
        if (status === 200) {
            setPricingCadre(data?.data)
        }
    }

    const fetchStates = async () => {
        let { data, status } = (await requestToServer(
            getStates,
            '',
            'get',
            true
        ))
        if (status === 200) {
            setStates(data?.data)
        }
    }


    useEffect(() => {
        fetchDomains();
        fetchPricingCadres();
        fetchStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const value = useMemo(
        () => ({
            domains,
            pricingCadres,
            states
        }),
        [
            domains,
            pricingCadres,
            states
        ]
    )
    return (
        <CommonApiContext.Provider value={value}>
            {children}
        </CommonApiContext.Provider>
    )
}

export const useCommonApi = () => {
    return useContext(CommonApiContext)
}
