import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import domains from '@iso/redux/domains/sagas';
import priceCadre from '@iso/redux/priceCadre/sagas';
import mentors from '@iso/redux/mentors/sagas';
import mentees from '@iso/redux/mentees/sagas';
import requests from '@iso/redux/requests/sagas';
import payments from '@iso/redux/payments/sagas';
import appointments from '@iso/redux/appointments/sagas';
import appointmentReviews from '@iso/redux/appointmentReviews/sagas';
import Payouts from '@iso/redux/payouts/sagas';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    domains(),
    priceCadre(),
    mentors(),
    mentees(),
    requests(),
    payments(),
    appointments(),
    appointmentReviews(),
    Payouts()
  ]);
}
