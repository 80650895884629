import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/Api
 */


function* loadFromApi(payload) {
  try {
    yield put(actions.loadFromApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* storeIntoApi() {
  try {
    yield put({ type: actions.LOAD_FROM_API });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoAPIError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SAVE_INTO_API, storeIntoApi),
  ]);
}
