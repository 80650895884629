import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { showErrorToaster } from '../utils/toasters';
import { useDispatch, useSelector } from 'react-redux';
import authAction from '@iso/redux/auth/actions';

const { logout } = authAction;
const statusCode = [401, 403, 422, 400, 500, 404, 409, 300]

axios.interceptors.response.use(
  (response) => {
    /* const { data, config } = response
    if (config.method !== 'get') {
      showsuccessToaster(data.message, data.message)
    } */
    return response
  },
  (error) => {
    if (
      error &&
      error.response &&
      statusCode.includes(error?.response?.status)
    ) {
      const { data } = error.response
      showErrorToaster(data?.message, data?.stack)
      return Promise.resolve(error.response)
    } else {
      return Promise.reject(error)
    }
  }
)

const getHeaders = (
  isAuthorized,
  authToken,
  isFormWithImg
) => {
  let config = {
    headers: {
      Accept: 'application/json',
    },
  }
  if (isAuthorized) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }
  if (isFormWithImg) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  return config.headers
}

const useRequestToServer = () => {
  const navigate = useHistory();
  const token = useSelector((state) => state.Auth.token);;
  const dispatch = useDispatch();

  const authToken = token || ''

  const requestToServer = (
    url,
    data,
    method,
    isAuthorized = false,
    isFormWithImg = false
  ) => {
    const API_URL = process.env.REACT_APP_BASE_URL;
    let requestUrl = `${API_URL}${url}`
    let headers = getHeaders(isAuthorized, authToken, isFormWithImg)
    return new Promise((resolve, reject) => {
      axios({ method, url: requestUrl, headers, data })
        .then((response) => {
          if (response && response.status === 401) {
            dispatch(logout())
            navigate.push('/signin')
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error, '-------------fff--------')
          reject(error)
        })
    })
  }

  return { requestToServer }
}

export default useRequestToServer
