import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  mentees: {},
  mentee: {
    key: null,
    id: null,
    uuid: new Date().getTime,
    fullName: '',
    email: '',
    countryCode: '',
    contactNumber: '',
    city: '',
    status: '',
    deleted_at: null, // soft delete
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mentees: payload.data,
        pagination: payload.pagination,
        errorMessage: false,
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        mentee: payload.data == null ? initState.mentee : payload.data,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.SET_MENTEE:
      return {
        ...state,
        mentee: payload.mentee,
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data,
      };
    default:
      return state;
  }
}
