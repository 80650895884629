import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Routes from './router';
import AppProvider from './provider/AppProvider';
// import { CommonApiProvider } from './provider/CommonApiProvider';
import Boot from './redux/boot';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
        <ToastContainer newestOnTop={true} limit={3} />
      </>
    </AppProvider>
  </Provider>
);

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
