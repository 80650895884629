import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .ant-btn{
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .formFieldErrorMessage {
    display: inline-block;
    margin: 0.25rem 0 0 0;
    color: #e11d48;
  }

  .QuestionPopup .alternate-slot {
    padding: 28px 0px;
  }
  .userSec {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .userSec .user-img {
    height: 83px;
    width: 83px;
    border: 3px solid #ffffff;
    margin-bottom: 12px;
    box-shadow: 0 7px 12px 3px rgba(21, 26, 40, 0.17);
  }
  .userSec h6 {
    font-family: 'Bitter', serif;
    color: #1b2339;
    font-weight: 600;
    padding-bottom: 6px;
  }
  .state {
    width:471px!important;
  }
  .userSec span {
    color: #5f779c;
    font-size: 12px;
    line-height: 16px;
  }

  .user-img {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    margin-bottom: 7px;
    position: relative;
  }
  .user-img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  .user-info {
    margin-top: 15px
  }

   img.position-relative {
    width: 73px !important;
    height: 73px !important;
    margin-bottom: 25px;
  }

  .banner-sec .form-control {
    color: #575757;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    padding: 28px 200px 28px 20px;
    border-radius: 9px;
    background-color: #ffffff;
    box-shadow: -3px 10px 24px 10px rgba(0, 0, 0, 0.03);
    border: none;
  }

  .DashboardFormMain {
    position: relative;
  }
  .DashboardFormMain .form-group.BtnSec {
    padding-top: 10px;
  }
  .DashboardFormMain .form-group.BtnSec .cancel-btn {
    margin-left: 25px;
  }
  .DashboardFormMain textarea.form-control {
    height: 130px;
    resize: none;
  }

  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .DashboardForm .multi-checkbox.dropdown-menu.show {
    transform: none !important;
    position: relative !important;
  }

  .DashboardForm {
    width: 100%;
    padding: 32px 0;
  }
  .DashboardForm .BtnSec .common-btn {
    width: auto;
    min-width: 100px;
  }

  h6 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
  }

  .CustomImageUpload.JobExp .EduSecInner h6 {
    padding-bottom: 0;
    color: #151a28;
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 23px;
    
  }

  .CustomImageUpload {
    border-bottom: 1px solid #eaf0ff;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  .ProfileImg .invalid-feedback {
    text-align: center;
    bottom: -25px;
  }
  .CustomImageUpload .custom-file {
    height: auto;
    cursor: pointer;
  }
  .CustomImageUpload .custom-file .form-group {
    margin-bottom: 0;
  }
  .CustomImageUpload .custom-file-label {
    height: auto;
    margin-bottom: 0;
    padding: 13px 20px;
    border: 1px dashed #0c5ce6;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #fafcff;
    cursor: pointer;
  }

  .CustomImageUpload .custom-file-input {
    height: 13%;
    position: absolute;
    top: 30px;
    left: 0;
    cursor: pointer;
  }
  .custom-file-inner {
    display: inline-flex;
    width: 100%;
  }
  .CustomImageUpload .custom-file-input:focus ~ .custom-file-label {
    border-color: #0c5ce6;
    box-shadow: none;
  }
  .CustomImageUpload .custom-control-label::before,
  .CustomImageUpload .custom-file-label,
  .CustomImageUpload .custom-select {
    transition: none;
  }

  .salution {
    padding: 4px 10px;
    width: 471px;
    height: 42.5px;
    cursor: pointer;
    text-align: left;
    font-size: 13px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e9e9e9;
    color: #595959;
    border-radius: 5px;
    margin-bottom:12px;
    margin-left:10px;
  }

  .d-none{display:none;}

  .ant-picker{
    border-radius:5px!important;
    height:41px!important;
    width:100%!important;
  }

  .form-control {
    border: 1px solid #d7deea;
    border-radius: 8px;
    background-color: #fafcff;
    color: #000000;
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
    height: auto;
  }
  .form-control:focus {
    box-shadow: none;
    color: #000000;
    background-color: #fafcff;
  }
  .form-contro [type='password'] {
    padding-right: 45px;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
    padding-right: 10px !important;
  }

  select.form-control {
    -webkit-appearance: none;
    background: url(../images/sel-arrow.svg) no-repeat;
    background-position: right 10px center;
  }

  .DashboardFormMain textarea.form-control {
    height: 130px;
    resize: none;
  }

  .form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.TitleSec .form-control {
  color: #151a28;
  font-weight: 600;
  background-color: #fafcff;
}

  .UploadImg {
    width:auto;
    height:auto;
  }
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }
  .CustomImageUpload .custom-file-label::after {
    display: none;
  }
  .CustomImageUpload .custom-file-label h5 {
    color: #151a28;
    font-size: 17px;
    letter-spacing: 0.13px;
    line-height: 23px;
  }
  .CustomImageUpload .custom-file-label div {
    padding-left: 10px;
  }
  .CustomImageUpload .custom-file-label span {
    color: #575757;
    font-size: 12px;
    line-height: 25px;
  }
  .CustomImageUpload .custom-file-label span b {
    color: #000;
    font-weight: normal;
  }

  .ProfileImg {
    position: relative;
  }
  .ProfileImgInner {
    position: relative;
    width: 70px;
    height: 70px;
    display: table;
    margin: 0 auto;
    border-radius: 100%;
    background: #f7f7f7;
    border: 1px solid #d3dded;
  }
  .ProfileImgInner .Userimg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
  .Camera {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    height: 25px;
    width: 25px;
    border: 1px solid #0c5ce6;
    background-color: #0c5ce6;
    box-shadow: 0 0 3px 4px rgba(0, 0, 0, 0.09);
    border-radius: 100%;
    bottom: -6px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rating-btn {
    margin-top:18px;
  }
  .UserImg {
    height: 34px;
    width: 34px;
    border: 1px solid #0c5ce6;
    background-color: #fafcff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
  }
  .UserImg img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 100%;
    object-fit: cover;
  }

 
  .UploadProfile .slot-title {
    padding: 24px 20px;
  }
  .UploadProfile .alternate-slot {
    padding: 0px;
  }
  .UploadProfile .form-group.BtnSec {
    margin-bottom: 0;
    border-top: 1px solid #eaf0ff;
    padding: 28px 0 0;
    margin-top: 28px;
  }

  .ReschedulePopup .alternate-slot {
    padding: 28px 0px;
  }
  .ReschedulePopup.slot-info .alternate-slot h5 {
    margin-bottom: 10px;
  }

  .UserImg img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  .TwoCol {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }
  .TwoCol .form-group,.TwoCol > div {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 10px;
    margin:0 0 26px
  }
  .TwoCol > div .form-control{
    width:100%
  }
  .TwoCol .invalid-feedback,
  .TwoCol div.text-danger {
    left: 13px;
    bottom: inherit;
    line-height: 13px;
  }

  .TitleSec .TwoCol {
    width: 495px;
  }
  .TitleSec .TwoCol .form-group,
  .TitleSec .FormSelect {
    margin-bottom: 0;
    width: 100%;
  }

  .FormSelect {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    padding: 0;
  }

  label.form-label {
    color: #000000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .radiobtn {
    /* padding-left: 25px; */
    margin-right: 12px;
  }
  .custom-control-label::after {
    height: 16px;
    width: 16px;
    border: 1px solid #d7deea;
    border-radius: 9px;
    box-shadow: none;
    background: transparent;
    left: 0;
    top: 0;
  }
  .custom-control-label::before {
    height: 6px;
    width: 6px;
    box-shadow: none;
    border-radius: 9px;
    background: transparent;
    left: 5px;
    top: 5px;
    border: none;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background: #ff8143;
    box-shadow: none;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    border-color: #ff8143;
  }
  .custom-control-label {
    font-size: 14px;
    font-weight: 600;
    color: #151a28;
    padding-left: 25px;
    line-height: 18px;
  }

   .btn-option .radiobtn .custom-control-label::after {
    border: 1px solid #0c5ce6;
    height: 21px;
    width: 21px;
    border-radius: 100%;
    top: -1px;
    left: -30px;
  }
  .btn-option .radiobtn .custom-control-label::before {
    width: 13px;
    height: 13px;
    left: -26px;
    top: 3px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #0c5ce6;
  }
  .btn-option .radiobtn .custom-control-label {
    line-height: normal;
    padding-left: 0;
    display: flex;
  }
  .btn-option .radiobtn {
    padding-left: 30px;
    display: flex;
  }

  .alternate-slot {
    padding: 28px 20px 0;
  }
  .slot-info .alternate-slot h5 {
    margin-bottom: 10px;
  }

  .slot-info .ClosePopup {
    background: #fff;
  }

  .slot-info h3 ,.ant-modal-header .ant-modal-title{
    color: #1b2339;
    font-family: Bitter!important;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .slot-title {
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    padding:0 0 30px 0px;
    display:flex;
  }

  .mentee-profile {
    position:absolute;
    right:90px;
  }
  .user-profile{
    display:flex;
    justify-content:space-around;
    padding-top: 30px; 
  }
  .user-profile >div{
    display:flex;
    flex-direction: column;
    align-items: center
  }
  .title {
    color: #1b2339;
    font-size: 14px;
    font-weight: bold;
  }

  .slot-info .slot-title h5,
  .slot-info .alternate-slot h5,
  .slot-info .proceed-slot h5 {
    color: #151a28;
    font-family: Bitter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 13px;
  }
  .slot-info .btn-option {
    display: flex;
    margin-bottom: 30px;
  }

.slot-info .btn-option .radiobtn label {
  margin-bottom: 8px;
}
.slot-info .btn-option .radiobtn span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.slot-info button {
  padding: 12px 56px;
  font-size: 14px;
  margin-right: 20px;
  text-transform: capitalize;
}

.slot-info button.border-btn:hover {
  background-color: #0c5ce6;
  color: #fff;
}

.slot-info .BtnSec.option-proceed {
  align-items: flex-start;
  padding: 28px 30px 30px 30px;
  margin: 0;
}
.slot-info .BtnSec.option-proceed .btn-option {
  flex-direction: column;
}
.slot-info .BtnSec.option-proceed h5 {
  margin-bottom: 15px;
}
.slot-info .BtnSec.option-proceed .btn-option .radiobtn {
  margin-bottom: 20px;
}
.slot-info .BtnSec.option-proceed .btn-option .radiobtn label {
  margin-bottom: 0;
}
.slot-info .BtnSec.option-proceed .btn-option .radiobtn:last-child {
  margin: 0;
}
.slot-info .BtnSec.option-proceed button {
  min-width: 113px;
  padding: 12px 32px;
  margin: 0;
}

.MentorDetailsPopup li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.MentorDetailsPopup li:last-child {
  padding-bottom: 0;
}
.MentorDetailsPopup li label {
  margin-bottom: 0;
  color: #575757;
  font-size: 14px;
  line-height: 19px;
  width: auto;
}
.MentorDetailsPopup li span {
  margin-bottom: 0;
  color: #1b2339;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}
.MentorDetailsPopup li span.status {
  font-weight: 600;
}

  .alternate-slot .Slot-sec:last-of-type {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .alternate-slot .btn-option .radiobtn {
    border: 1px solid #e3e9f3;
    border-radius: 10px;
    background-color: #fafcff;
    padding: 26px 26px 26px 68px;
    display: flex;
    flex-direction: column;
    max-width: 250px;
    width: 100%;
  }

  .pop-footer {
    border-top: 1px solid #eaf0ff;
    padding: 28px 0 0;
    margin-top: 28px;
  }
  .pop-footer a {
    color: #ff8143;
    font-weight: bold;
    font-size: 16px;
  }

  .ReschedulePopup .pop-footer {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 28px;
  }
  .ReschedulePopup .pop-footer button {
    margin-right: 0;
    width: 100%;
  }

  .CheckoutPopup .pop-footer .common-btn {
  width: 100%;
}
.CheckoutPopup .pop-footer {
  padding: 28px 20px;
  margin-top: 0;
}

  .ReschedulePopup .alternate-slot .Slot-sec:last-of-type {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .CheckoutPopup .alternate-slot {
    padding: 28px 20px;
  }

  .ql-container.ql-snow{
    height:100px;
  }

  textarea::-webkit-input-placeholder {
    color: rgba(0,0,0,0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }

  .form-group {
    margin: 0 auto;
    position: relative;
    // padding-bottom: 25px;
  }
  .w-100{width:100%}
  .form-group::before {
    content: '';
    background: url(/ele2.svg);
    width: 140px;
    height: 50px;
    left: -180px;
    top: 50px;
    position: absolute;
    background-size: contain;
  }

  .form-group .common-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .form-group {
    position: relative;
    margin-bottom: 26px;
  }
  .form-group.TndC {
    padding-top: 10px;
  }

  .form-group.BtnSec {
    padding-top: 10px;
  }

  .custom-file-input.resume {
    height:76%!important;
    top:0px!important;
    cursor:pointer; 
  }

  .Checkbox {
    display: flex;
    flex-wrap: wrap;
  }
  .Checkbox .TndC .form-check-label {
    color: #151a28;
    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
    margin-left:6px;
  }
  .Checkbox .TndC {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  .Checkbox {
    padding-top: 8px;
  }

  // input[type='checkbox'] {
  //   position: relative;
  //   border: 1px solid #a0a4a5;
  //   border-radius: 2px;
  //   background: none;
  //   cursor: pointer;
  //   line-height: 0;
  //   margin: -1px 9px 0 0;
  //   outline: 0;
  //   padding: 0 !important;
  //   vertical-align: text-top;
  //   height: 16px;
  //   width: 16px;
  //   -webkit-appearance: none;
  //   opacity: 1;
  // }
  // input[type='checkbox']:before {
  //   content: '';
  //   position: absolute;
  //   right: 50%;
  //   top: 50%;
  //   width: 4px;
  //   height: 8px;
  //   border: solid #ff8143;
  //   border-width: 0 2px 2px 0;
  //   margin: 0 -1px 0 -1px;
  //   transform: rotate(45deg) translate(-50%, -50%);
  //   z-index: 2;
  //   opacity: 0;
  // }
  // input[type='checkbox']:checked:before {
  //   opacity: 1;
  // }
  // input[type='checkbox']:checked {
  //   border: 1px solid #ff8143;
  // }

  .EduSecInner {
    display: flex;
    border: 1px solid #e3e9f3;
    border-radius: 10px;
    background-color: #fafcff;
    align-items: center;
    padding: 25px 10px 25px 30px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .EduSecInner h6 {
    color: #151a28;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding-bottom: 8px;
  }

  .EduSecInner p {
    color: #5f779c;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .EduSec {
    width: 100%;
  }

  .EduSecInner h6 img {
    margin-right: 8px;
  }

  .isomorphicTopbar .isoRight li.isoUser .isoImgWrapper {
    background-color:#000000!important;
  }

  .EduSecInner .EduLft {
    width: calc(100% - 70px);
    display: block;
  }
  .EduSecInner .EduLft p,
  .EduSecInner .EduLft span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .EditIcon button {
    border: none;
    padding: 0;
    background: transparent;
    margin-left: 20px;
    font-size:22px;
    cursor: pointer
  }
  td.ant-table-cell i {
    font-size: 22px!important;
}
  button.add-btn {
    border: none;
    padding: 0;
    background: transparent;
  }
  button.add-btn {
    border: none;
    padding: 0;
    background: transparent;
    color: #ff8143;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 19px;
  }

  .price-cadre {
    padding: 4px 10px;
    width: 961px;
    height: 42.5px;
    cursor: pointer;
    text-align: left;
    font-size: 13px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e9e9e9;
    color: #595959;
    border-radius: 5px;
    margin-bottom:12px;
  }

  .cadre {
    margin-left:0px;
  }

  .CustomImageUpload.JobExp .EduSecInner {
    border: 1px dashed #0c5ce6;
    margin: 0;
    max-width:50%
  }
  .CustomImageUpload {
    margin-bottom: 30px;
  }
  .CustomImageUpload .form-group,.CustomImageUpload .custom-file{
    max-width:50%;
    margin:0
  }
  .DashboardForm .multi-checkbox.dropdown-menu.show {
    transform: none !important;
    position: relative !important;
  }
  .multi-checkbox.dropdown-menu.show {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 0;
    width: 100%;
    border: 1px solid #d7deea;
    border-radius: 8px;
    background: #fafcff;
    padding: 20px 10px 0;
    overflow: auto;
    height: 200px;
  }
  .multi-checkbox.dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }
  
  .multi-checkbox.dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px#FAFCFF;
    border-radius: 8px;
  }
  
  .multi-checkbox.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #151a28;
    border-radius: 8px;
    outline: none;
  }
  .form-group.MultiSel {
    display: inline-block;
    width: 100%;
  }

  .Checkbox.Checkbox_Main .TndC {
    margin-bottom: 8px;
  }

  .TndC .form-check-label {
    color: #000000;
    font-size: 11px;
    font-weight: 600;
  }
  .TndC .form-check-label a {
    color: #000;
    text-decoration: underline;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

  .form-group.BtnSec.option-proceed h5 {
    color: #151a28;
    font-family: Bitter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 26px;
  }

  .ant-radio-inner::after{
     top:12px!important;
     left:12px!important;
  }

  .MultiSel .dropdown button {
    width: 100%;
    text-align: left;
    color: #151a28;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #d7deea;
    border-radius: 8px;
    background-color: #fafcff;
    padding:2px 25px 3px 11px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 41px;
    align-items: center;
  }
  .MultiSel .btn-primary:not(:disabled):not(.disabled).active,
  .MultiSel .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #151a28;
    border: 1px solid #d7deea;
    background-color: #fafcff;
  }
  .MultiSel .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 16px;
  }
  .MultiSel span.Btch {
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
    border-radius: 15px;
    background-color: #ff8143;
    padding: 6px 10px;
    margin-right: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .MultiSel span.Btch::before,
  span.Btch::before {
    content: none;
  }
  .MultiSel .form-check {
    color: #151a28;
    font-size: 14px;
    padding-left: 0;
    padding-bottom: 20px;
    width: 50%;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  input[type='checkbox'] {
    position: relative;
    border: 1px solid #a0a4a5;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: -1px 9px 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 16px;
    width: 16px;
    min-width: 16px;
    -webkit-appearance: none;
    opacity: 1;
  }
  input[type='checkbox']:before {
    content: '';
    position: absolute;
    right: 48%;
    top: 50%;
    width: 4px;
    height: 8px;
    border: solid #ff8143;
    border-width: 0 2px 2px 0;
    margin: 0 -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    /* z-index: 2; */
    opacity: 0;
  }
  input[type='checkbox']:checked:before {
    opacity: 1;
  }
  input[type='checkbox']:checked {
    border: 1px solid #ff8143;
  }
  input[type='checkbox'] + label {
    cursor: pointer;
  }

  .verif-req {
    position: relative;
    left: 40%;
}

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.LeftAlign{
  justify-content: flex-start;
}
.LeftAlign button{
  margin-right:20px
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}
.top-head-main h3, body h3.isoBoxTitle{
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  color: #151a28;
  font-family: Bitter,serif;
}
.ant-modal-title{
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: #151a28;
  font-family: Bitter,serif;
}
.top-head-right {
  display: flex;
  align-items: center;
}
.form-control,.form-control.ant-input,.ant-input,.salution{
  border: 1px solid #d7deea!important;
  border-radius: 8px!important;
  padding: 10px!important;
  font-family: Nunito Sans,sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 19px!important;
  height: auto!important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
  min-width: 180px!important;
  color: #000!important;
}
.form-control:focus,.form-control.ant-input:focus,.ant-input:focus,.salution:focus{
  box-shadow: none!important;
  border-color: #80bdff!important;
  outline: none!important;
  background: #fff!important;
}
.ant-modal-body label{
  width: 100%;
  display: block;
}
.ant-modal-body .form-control,.cadre .form-control{
  width: 100%;
}
button.ant-btn.ant-btn-primary, .UploadProfile .form-group.BtnSec button.ant-btn.ant-btn-submit.common-btn{
  color: #fff;
  padding: 10px 35px;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
  border-radius: 30px;
  background-color: #0c5ce6!important;
  min-width: 125px;
  border: 1px solid #0c5ce6!important;
  cursor: pointer;
  opacity: 1;
  height: auto;
}
.UploadProfile .form-group.BtnSec button.ant-btn.ant-btn-submit.common-btn{position:static}
button.ant-btn.ant-btn-primary:hover, .UploadProfile .form-group.BtnSec button.ant-btn.ant-btn-submit.common-btn:hover{
  background: transparent!important;
  color: #0c5ce6!important;
}

button.ant-btn.ant-btn-default{
  color: #fff;
  padding: 10px 35px;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
  border-radius: 30px;
  background-color: #bbb!important;
  min-width: 125px;
  border: 1px solid #bbb!important;
  cursor: pointer;
  opacity: 1;
  height: auto;
}
button.ant-btn.ant-btn-default:hover{
  background: transparent!important;
  color: #bbb!important;
}
select.form-control {
  -webkit-appearance: auto!important;
}
.top-head-right div, .top-head-right select.form-control{
  margin-left: 20px;
}

.ant-layout table thead th {
  border: none!important;
  color: #7a7a7a!important;
  font-size: 12px!important;
  font-weight: 700!important;
  line-height: 16px!important;
  padding: 20px!important;
  background-color: #fafcff!important;
}
.ant-layout table {
  border-collapse: collapse!important;
  border: 1px solid #eaf0ff!important;
  border-radius: 8px!important;
  background-color: #fff!important;
  margin: 30px 0!important;
}
.ant-layout table tr td {
  color: #151a28!important;
  font-size: 14px!important;
  font-weight: 600!important;
  line-height: 19px!important;
  padding: 30px 20px 25px!important;
}
.ant-layout table tr td:nth-child(2),.ant-layout table tr th:nth-child(2) {
  max-width: 290px!important;
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
.ant-layout table thead th, .ant-layout .table tr td {
  vertical-align: middle!important;
  border-bottom: 1px solid #eaf0ff!important;
  letter-spacing: 0!important;
  max-width: 25%;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  content: none!important;
}
.switcherToggleBtn{
  display:none!important
}
aside.ant-layout-sider.ant-layout-sider-dark{background-color: #0c5ce6;padding:20px}
aside.ant-layout-sider.ant-layout-sider-dark .isoLogoWrapper{background-color: #0c5ce6;text-align:left;height:auto;padding:0}
aside.ant-layout-sider.ant-layout-sider-dark .isoLogoWrapper a{text-align:left}
aside.ant-layout-sider.ant-layout-sider-dark .nav-text{
  font-family: Nunito Sans,sans-serif!important;font-weight: 600!important;
}
aside.ant-layout-sider.ant-layout-sider-dark span.isoMenuHolder,aside.ant-layout-sider.ant-layout-sider-dark span.ant-menu-title-content a{color: hsla(0,0%,100%,.6)!important;}
aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-item-selected{
  border-radius: 8px!important;
    padding: 13px!important;
    background-color: hsla(0,0%,100%,.12)!important;
}
aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-item{
  border-radius: 8px!important;
    padding: 13px!important;
    margin-top: 10px!important;
}
aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active{
  background-color: hsla(0,0%,100%,.12)!important;
}
aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-submenu-title{padding: 13px!important;}
aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-item i,aside.ant-layout-sider.ant-layout-sider-dark .ant-menu-submenu-title i{margin-right: 10px!important;}
.collapsed-logo{opacity:0}
.LeftStaticImage {
  height: 100%;
  width: 50%;
  flex: 0 0 50%;
  position: relative;
  background: #f0f6ff;
  border-radius: 11px;
  display: flex;
    align-items: center;
}
.img-col-sec p strong {
  font-weight: 600;
  font-style: normal;
  color: #f5a623;
}
.AuthSec{background:none!important;padding: 10px!important;}
.AuthSec:before{
  content:none!important
}
.LeftStaticImageCon {
  z-index: 9;
  padding: 40px 80px;
  bottom: 0;
  color: #fff;
}
.LeftStaticImageCon a.Logo {
  display: inline-flex;
}
.LeftStaticImageCon h3 {
  color: #000;
  padding-bottom: 25px;
  font-size: 35px;
  font-weight: 500;
  line-height: 55px;
  font-family: Bitter,serif;
}
.LeftStaticImageCon .img-col-sec {
  display: flex;
  align-items: center;
  padding-top: 0;
}
.LeftStaticImageCon ul.img-collaspe {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}
.LeftStaticImageCon ul.img-collaspe li {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
}
.LeftStaticImageCon ul.img-collaspe li:nth-child(2), .LeftStaticImageCon ul.img-collaspe li:nth-child(3), .LeftStaticImageCon ul.img-collaspe li:nth-child(4), .LeftStaticImageCon ul.img-collaspe li:nth-child(5), .LeftStaticImageCon ul.img-collaspe li:nth-child(6) {
  margin-left: -20px;
}

.LeftStaticImageCon .img-col-sec p {
    color: #575757;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    padding-left: 8px;
}
.DashboardSecBodyInner {
  padding: 0 20px;
  height: calc(100% - 152px);
}
.notification-header{
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.notification-header h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  color: #151a28;
  font-family: Bitter,serif;
} 

.notification-header a{
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #ff8143;
  font-weight: 700;
}

.notication-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  padding: 16px 20px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #eaf0ff;
}
.notification-modal .modal-body {
  background: #f7f8fb;
  padding-top: 50px;
}
.notication-card_lft {
  display: flex;
  align-items: flex-start;
}
.notication-card_lft figure {
  margin-bottom: 0;
  margin-right: 10px;
}
.notication-card h6 {
  font-family: 'Bitter', serif;
  color: #1b2339;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 6px;
  line-height: 19px;
}

.notication-card p {
  color: #575757;
  font-size: 14px;
  line-height: 25px;
}
.notication-card strong {
  color: #1b2339;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.notification-modal .container {
  max-width: 1010px;
}
.notication-card.unread {
  background: #f0f6ff;
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.companyLogo{
  padding:20px
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none !important;
}

.infinite-scroll-component {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.filter{
  color: #ff8143;
  font-weight: 700;
  cursor: pointer;
}
.filter > span {
  min-width: 70px;
  display: block;
} 

/* Text Primary */
.text-primary {
  color: #007bff; /* This is the default primary color in Bootstrap */
}

/* Animation Keyframes */
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}
`;

export default GlobalStyles;
